import { instance, urlEndpoints } from './apiHelper'

export async function getDSPresignedUploadUrl(params) {
   const result = await instance.post(urlEndpoints.GET_DS_PRESIGNED_URL, {
      ...params,
   })
   return result.data
}

export async function getDSModelRun(params) {
   console.log('utilparams', params);
   const result = await instance.get(urlEndpoints.DS_MODEL_RUN, {
      params,
   })
   return result?.data ?? {};
}

export const dsConfig = {
   getDSPresignedUploadUrl,
   getDSModelRun,
}
