import { instance, urlEndpoints } from './apiHelper'

async function updateVisualInspectionConfig(data) {
   const result = await instance.post(urlEndpoints.UPDATE_VISUAL_INSPECTION_CONFIG, data)
   return result.data
}

export const visualInspectionConfig = {
   updateVisualInspectionConfig,
}
