/* eslint-disable prettier/prettier */
// Anything exported from this file is importable by other in-browser modules.
import { BehaviorSubject } from 'rxjs'
import { intialGlobalState } from './rxjs/intialState'

//Exporting APIs
export { authenticationGuard } from './api/authenticationGuard'

export { instance } from './api/apiHelper'

export { websocket } from './websocket'
export { globalWebsocket } from './websocket/globalWSS'

export const config = process.env

export { company } from './api/company'
export { logApi } from './api/logApi'
export { entity } from './api/entity'
export { user } from './api/user'
export { role } from './api/role'
export { businessUnit } from './api/businessUnit'
export { plants } from './api/plants'
export { factoryHealth } from './api/factoryHealth'
export { standardOperatingConditions } from './api/standardOperatingConditions'
export { recipe } from './api/recipe'
export { factoryGeneralConfig } from './api/factoryGeneralConfig'
export { oee } from './api/oee'
export { notification } from './api/notification'
export { dataReports } from './api/dataReports'
export { qualityManagement } from './api/qualityManagement'
export { equipmentConstraintsConfigurationAPIs } from './api/equipmentConstraintConfiguration'
export { biConfig } from './api/biConfig'
export { digitalTwin } from './api/digitalTwin'
export { ppeInsights } from './api/ppeInsights'
export { pmConfig } from './api/pmConfig'
export { genciConfig } from './api/genCIConfig'
export { ypConfig } from './api/ypConfig'
export { mduAPIs } from './api/mduAPIs'
export { visualInspectionConfig } from './api/visualInspectionConfig'

export { dds } from './api/dds'
export { cc } from './api/commandCenter'

// Exporting UI-URL contants
export { URL } from './ui/urls'

//MockData
export { user as mockUser } from './mock/api/user'
export { role as mockRole } from './mock/api/role'

// Exporting GlobalState : RXJS
export const globalstate$ = new BehaviorSubject(intialGlobalState)

// Exporting updateEditState For Unsaved Changes Navigation
export const updateEditState = (actionObj = {}, editStateObj = {}, type) => {
   const state = globalstate$.getValue()
   globalstate$.next({
      ...state,
      [type]: {
         ...state[type],
         ...editStateObj,
         action: {
            ...state[type].action,
            ...actionObj,
         },
      },
   })
}

// Exporting theme
export {
   default as theme,
   globeColors,
   watchListColors,
   SwitchColors,
   globeFontStyles,
   performanceColors,
   performanceFontStyles,
} from './theme'

// Exporting GlobeStyles
export { default as GlobalStyles } from './GlobalStyles'
export { splitRootFactory } from './containers/split'

export {
   RBAC_BUTTONS,
   EDIT_STATE_KEYS,
   DDS_CONSTANTS,
   FEATURE_FLAGS,
   RBAC_CONSTANTS,
} from './constants'
export {
   RBACValidation,
   getRoleThresholdValue,
   getHighestRBACStatus,
} from './rbac'
